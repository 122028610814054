import { useState, useContext } from "react";

import styled from "styled-components";

import { useTranslation } from "react-i18next";
import { Button, Avatar as StyledAvatar } from "antd";
import Link from "next/link";
import FavoriteIcon from "../../../assets/favorite.svg";
import ChatIcon from "../../../assets/chat.svg";
import SearchIcon from "../../../assets/search.svg";
import MenuIcon from "../../../assets/menu.svg";

import { Avatar, Badge, theme, SearchModal, Rank } from "@project/shared";
import { useRouter } from "next/router";
import { DrawerContainer } from "../../molecules";
import { AuthContext } from "../../../utils/AuthContext";

import { UserOutlined } from "@ant-design/icons";

const Wrapper = styled.div<{ authenticated: any }>`
  min-height: 80px;
  display: flex;
  max-width: 1440px;
  margin: auto;
  width: 100%;
  align-items: center;
  padding: ${({ authenticated }) =>
    authenticated ? "0px 154px" : "0px 220px 0px 154px"};
  & > .logo {
    display: content !important;
  }
  @media (min-width: 1749px) {
    padding: 0px;
  }
  @media (max-width: 1375px) {
    padding: 0px 120px;
  }
  @media (max-width: 1075px) {
    padding: 0px 80px;
  }
  @media (max-width: 980px) {
    padding: 0px 50px;
  }
  @media (max-width: 920px) {
    padding: 0px 26px;
  }
  @media (max-width: 875px) and (min-width: 768px) {
    & .logo img {
      width: 100px;
    }
  }
  @media (max-width: 768px) {
    min-height: 56px;
    padding: 0px 16px;
    & > .logo > img {
      width: 96px;
      height: 22px;
    }
  }
`;

const LinksWrapper = styled.div`
  & > a {
    margin-right: 24px;
    font-size: 16px;
    white-space: nowrap;
    color: ${theme.planeText};
  }
  & > a:hover {
    color: ${theme.primary};
  }
  & > a:first-child {
    margin-left: 36px;
  }
  & > a:last-child {
    margin-right: 10px;
  }
  @media (max-width: 1225px) and (min-width: 769px) {
    & a {
      font-size: 14px;
    }
  }
  @media (max-width: 1175px) and (min-width: 769px) {
    display: flex;
    & > a:first-child {
      margin-left: 20px;
    }
  }

  @media (max-width: 1125px) and (min-width: 769px) {
    & a {
      font-size: 12px;
      margin-right: 16px;
    }
  }
  @media (max-width: 800px) and (min-width: 769px) {
    & a {
      margin-right: 16px;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const ButtonWrapper = styled.div`
  margin-left: auto;
  & > button:first-child {
    margin-right: 16px;
    background: ${theme.primary};
    border: ${theme.primary};
  }
  & > button {
    min-width: 90px;
    min-height: 37px;
    border-radius: 4px;
  }
  & > .ant-btn-ghost {
    color: ${theme.primary};
    border-color: ${theme.primary};
  }
  @media (max-width: 768px) {
    & > button:first-child {
      margin-right: 16px;
    }
  }
  @media (max-width: 768px) {
    & button {
      min-height: 37px;
    }
  }
  @media (max-width: 450px) {
    & button {
      min-height: 33px;
    }
  }
  @media (max-width: 350px) {
    & button {
      min-width: 55px;
      min-height: 30px;
      font-size: 12px;
    }
  }
`;
const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  & svg {
    cursor: pointer;
  }
  & > .favorite {
    margin-right: 26px;
  }
  & > .chat {
    margin-right: 19px;
  }
  & > .notice {
    margin-right: 17px;
  }
  & path:hover {
    fill: ${theme.primary};
  }
  & .ant-avatar:hover path {
    fill: ${theme.base};
    opacity: 0.7;
  }
  @media (max-width: 1125px) and (min-width: 769px) {
    & > .favorite {
      margin-right: 16px;
    }
  }
  @media (max-width: 768px) {
    & > .notice {
      margin-right: 21px;
    }
    & .favorite,
    .avatar {
      display: none;
    }
  }
  @media (min-width: 769px) {
    & .search {
      display: none;
    }
    & > .menu-icon {
      display: none;
    }
  }
  & .search {
    margin-right: 26px;
  }
`;

const Username = styled.span`
  margin-right: 8px;
  font-size: 14px;
  max-width: 150px;
  color: ${theme.planeText};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */
  @media (max-width: 825px) {
    font-size: 12px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
const StyledUserAvatar = styled(StyledAvatar)`
  @media (max-width: 768px) {
    display: none;
  }
`;

const Container = styled.div`
  background-color: ${theme.base};
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 0px;
  z-index: 1000;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
`;
const LinkItem = styled.a<{ active?: boolean }>`
  color: ${({ active }) =>
    active ? theme.primary : theme.planeText} !important;
  &:hover {
    color: ${theme.primary} !important;
  }
`;

const RankContainer = styled.div`
  min-width: 90px;
`;

interface HeaderProps {
  isAuthenticated?: boolean;
}
const Header = (props: HeaderProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  const {
    user,
    authenticated,
    profile,
    username,
    messageCount,
    rank,
  } = useContext(AuthContext);
  const [showDrawer, setShowDrawer] = useState(false as boolean);
  const [showSearch, setShowSearch] = useState(false);

  return (
    <Container>
      <Wrapper authenticated={props.isAuthenticated && user}>
        <Link
          href={
            props.isAuthenticated && user ? "/" : "https://www.sidebizz.net/"
          }
        >
          <a className="logo">
            <img src={"/logo.svg"} alt="side bizz" height="32" width="138" />
          </a>
        </Link>
        {authenticated !== null &&
          router.pathname !== "/inquiry" &&
          (props.isAuthenticated && user ? (
            <>
              <LinksWrapper>
                <Link
                  href={{
                    pathname: "/search",
                    query: {
                      page: 1,
                      jobType: "rankings",
                      category: "all",
                    },
                  }}
                  passHref
                >
                  <LinkItem active={router.pathname === "/search"}>
                    {t("Search jobs")}
                  </LinkItem>
                </Link>
                <Link href="/entries" passHref>
                  <LinkItem active={router.pathname === "/entries"}>
                    {t("Entry")}
                  </LinkItem>
                </Link>
                <Link href="/referrals" passHref>
                  <LinkItem active={router.pathname === "/referrals"}>
                    {t("Referral")}
                  </LinkItem>
                </Link>
                <Link href="/scouts" passHref>
                  <LinkItem active={router.pathname === "/scouts"}>
                    {t("Scout")}
                  </LinkItem>
                </Link>
                <Link href="/member-support" passHref>
                  <LinkItem active={router.pathname === "/member-support"}>
                    {"会員サポート"}
                  </LinkItem>
                </Link>
                <Link href="/faq" passHref>
                  <LinkItem active={router.pathname === "/faq"}>
                    {"サイトの使い方"}
                  </LinkItem>
                </Link>
              </LinksWrapper>
              <ActionsWrapper>
                <Link href="/favorites">
                  <a className="favorite">
                    <FavoriteIcon />
                  </a>
                </Link>
                <div className="search" onClick={() => setShowSearch(true)}>
                  <SearchIcon />
                </div>
                <Badge
                  count={messageCount !== 0 ? messageCount : null}
                  icon={<ChatIcon />}
                  className="chat"
                  href="/chat"
                />
                <RankContainer>
                  {username && <Username>{username}</Username>}
                  <Rank type={rank} />
                </RankContainer>
                <Link href="/settings">
                  <a>
                    {profile ? (
                      <Avatar className="avatar" size={38} src={profile} />
                    ) : (
                      <StyledUserAvatar size={38} icon={<UserOutlined />} />
                    )}
                  </a>
                </Link>
                <div
                  className="menu-icon"
                  onClick={() => setShowDrawer(!showDrawer)}
                >
                  <MenuIcon />
                  <DrawerContainer showDrawer={showDrawer} />
                </div>
              </ActionsWrapper>
            </>
          ) : (
            <ButtonWrapper>
              <Button onClick={() => router.push("/signup")} type="primary">
                {t("Sign up")}
              </Button>
              <Button onClick={() => router.push("/login")} type="ghost">
                {t("Login")}
              </Button>
            </ButtonWrapper>
          ))}
      </Wrapper>
      <SearchModal visible={showSearch} onClose={() => setShowSearch(false)} />
    </Container>
  );
};

export { Header };
