import { Drawer } from "antd";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { HeartIcon } from "@project/shared/src/assets/icons";
import { theme, Avatar, firebase } from "@project/shared";
import { AuthContext } from "../../../utils/AuthContext";

const Container = styled(Drawer)`
  & .ant-drawer-body {
    padding: 16.5px 21px 144px 17px;
  }
  & .row {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    & > a,
    span {
      ${theme.typography.typographyLarge};
    }
    & span {
      cursor: pointer;
    }
  }
  & .ant-drawer-content-wrapper {
    max-width: 335px;
    width: 100% !important;
  }
`;
const FirstRow = styled.div`
  & > span {
    margin-left: 8px;
  }
`;

const SecondRow = styled.a<{ active?: boolean }>`
  margin-top: 34.5px;
  & > span {
    margin-left: 8px;
    color: ${({ active }) =>
      active ? theme.primary : theme.planeText} !important;
  }
`;

const InnerContainer = styled.div`
  margin-top: 16px;
`;

const Border = styled.div`
  margin-left: -17px;
  width: calc(100% + 38px);
  border-bottom: 1px solid ${theme.border};
`;

const LinkItem = styled.a<{ active?: boolean }>`
  color: ${({ active }) =>
    active ? theme.primary : theme.planeText} !important;
  &:hover {
    color: ${theme.primary} !important;
  }
`;

interface Props {
  showDrawer: boolean;
}

const DrawerContainer: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setUser, username, profile, user } = useContext(AuthContext);

  const { showDrawer } = props;

  const handleSignOut = async () => {
    await firebase.auth().signOut();
    router.push("/login");
    window.localStorage.removeItem("timer");
    setUser(null);
    localStorage.removeItem("lastUsageAt");
  };

  const isLoggedInWithPassword = () => {
    let withPassword = false;
    user?.providerData?.map((provider: any) => {
      if (provider?.providerId === "password") {
        withPassword = true;
      }
    });
    return withPassword;
  };

  return (
    <Container placement="right" visible={showDrawer}>
      <FirstRow className="row">
        {profile ? <Avatar src={profile} size={38} /> : <Avatar size={38} />}

        <span>{username}</span>
      </FirstRow>
      <Link href="/favorites" passHref>
        <SecondRow className="row" active={router.pathname === "/favorites"}>
          <HeartIcon />
          <span>{t("Saved jobs")}</span>
        </SecondRow>
      </Link>
      <Border />
      <InnerContainer>
        <div className="row">
          <Link
            href={{
              pathname: "/search",
              query: {
                page: 1,
                jobType: "rankings",
                category: "referral",
              },
            }}
            passHref
          >
            <LinkItem active={router.pathname === "/search"}>
              {t("Search jobs")}
            </LinkItem>
          </Link>
        </div>
        <div className="row">
          <Link href="/entries" passHref>
            <LinkItem active={router.pathname === "/entries"}>
              {t("Entry")}
            </LinkItem>
          </Link>
        </div>
        <div className="row">
          <Link href="/referrals" passHref>
            <LinkItem active={router.pathname === "/referrals"}>
              {t("Referral")}
            </LinkItem>
          </Link>
        </div>
        <div className="row">
          <Link href="/scouts" passHref>
            <LinkItem active={router.pathname === "/scouts"}>
              {t("Scout")}
            </LinkItem>
          </Link>
        </div>
        <div className="row">
          <Link href="/member-support" passHref>
            <LinkItem active={router.pathname === "/member-support"}>
              {t("会員サポート")}
            </LinkItem>
          </Link>
        </div>
        <div className="row">
          <Link href="/faq" passHref>
            <LinkItem active={router.pathname === "/faq"}>
              {t("サイトの使い方")}
            </LinkItem>
          </Link>
        </div>
      </InnerContainer>
      <Border />

      <InnerContainer>
        <div className="row">
          <Link href="/settings?section=profile" passHref>
            <LinkItem
              active={
                router.pathname === "/settings" &&
                router.query?.section === "profile"
              }
            >
              {t("Edit profile")}
            </LinkItem>
          </Link>
        </div>
        <div className="row">
          <Link href="/settings?section=introduce-companies" shallow>
            <LinkItem
              active={
                router.pathname === "/settings" &&
                router.query?.section === "introduce-companies"
              }
            >
              {t("Introduce Companies")}
            </LinkItem>
          </Link>
        </div>
        <div className="row">
          <Link href="/settings?section=rank" shallow>
            <LinkItem
              active={
                router.pathname === "/settings" &&
                router.query?.section === "rank"
              }
            >
              {t("Rank and points")}
            </LinkItem>
          </Link>
        </div>
        <div className="row">
          <Link href="/bank-account" passHref>
            <LinkItem active={router.pathname === "/bank-account"}>
              {t("Edit bank account")}
            </LinkItem>
          </Link>
        </div>
        {isLoggedInWithPassword() && (
          <>
            <div className="row">
              <Link href="/change-email" passHref>
                <LinkItem active={router.pathname === "/change-email"}>
                  {t("Change email")}
                </LinkItem>
              </Link>
            </div>
            <div className="row">
              <Link href="/change-password" passHref>
                <LinkItem active={router.pathname === "/change-password"}>
                  {t("Change password")}
                </LinkItem>
              </Link>
            </div>
          </>
        )}
        <div className="row">
          <span onClick={() => handleSignOut()}>{t("Logout")}</span>
        </div>
      </InnerContainer>
      <Border />
    </Container>
  );
};

export { DrawerContainer };
