import firebase from "firebase";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { GetUserIDFromUUID } from "../services/sbMembers";

export const useAdditionalScript = () => {
  const [userID, setUserID] = useState(null);
  useQuery<any>("get-id-from-uuid", () => GetUserIDFromUUID(userID), {
    enabled: !!userID,
    onSuccess: (data) => {
      if (!document.getElementById("marketingScript")) {
        const conf = document.createElement("script");
        conf.innerHTML = `if (!window.afblpcvCvConf) {
                window.afblpcvCvConf = [];
            }
            window.afblpcvCvConf.push({
                siteId: "536240e2",
                commitData: {
                pid: "b14241F",
                u: "${data?.data?.id}"
                }
            });
        `;

        const script = document.createElement("script");
        script.setAttribute(
          "src",
          "https://t.afi-b.com/jslib/lpcv.js?cid=536240e2&pid=b14241F"
        );
        script.setAttribute("async", "async");
        script.setAttribute("id", "marketingScript");
        document.body.prepend(script);
        document.body.prepend(conf);
      }
    },
  });

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        setUserID(user.uid);
      }
    });
    return unsubscribe;
  }, []);
};
