import * as Sentry from "@sentry/node";
import { AppProps } from "next/app";
import {
  analytics,
  Footer,
  GlobalStyle,
  theme,
  firebase,
  TopProgressBar,
  i18n,
  ChatOptimizationProvider,
} from "@project/shared";
import { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { useRouter } from "next/router";
import "../utils/css-imports";
import styled from "styled-components";
import "../utils/globalStyles.css";
import { Layout, ConfigProvider } from "antd";
import { Header } from "../components/organism/Header";
import "nprogress/nprogress.css";
import { AuthProvider } from "../utils/AuthContext";
import { Content } from "antd/lib/layout/layout";
import Head from "next/head";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { QueryClient, QueryClientProvider } from "react-query";

import jaJP from "antd/lib/locale/ja_JP";
import enUS from "antd/lib/locale/en_US";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { MarketingScript } from "../components/molecules/MarketingScript";

const LayoutWrapper = styled.div<{ minHeight: string }>`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  & .ant-layout-header {
    height: 54px;
    padding: 0 50px;
    color: rgba(0, 0, 0, 0.85);
  }
  & .ant-layout-content {
    display: flex;
    flex-direction: column;
    min-height: 45vh;
  }
  & .ant-layout {
    min-height: 100vh;
    background-color: ${theme.background};
    position: relative;
  }
  @media (max-width: 768px) {
    min-height: ${({ minHeight }) => minHeight};

    & .ant-layout {
      min-height: ${({ minHeight }) => minHeight};
    }
  }
`;

export { Layout };
if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    // enabled: process.env.NODE_ENV !== "development",
    environment: `user-${process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT}`,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}
const noHeaderRoutes = [
  "/login",
  "/email-verification",
  "/signup",
  "/forgot-password",
  "/messages/[id]",
];
const queryClient = new QueryClient();
const MyApp = ({ Component, pageProps }: AppProps) => {
  const [minHeight, setMinHeight] = useState("80vh");
  const routers = useRouter();

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      queryClient.setDefaultOptions({
        queries: {
          enabled: user !== null,
        },
      });
    });
    if (process.env.NODE_ENV === "production") {
      const logEvent = (url: string) => {
        analytics().setCurrentScreen(url);
        analytics().logEvent("screen_view", {
          screen_name: url,
          app_name: "Sidebizz-User",
        });
      };

      routers.events.on("routeChangeComplete", (url) => {
        if (window) {
          window.scrollTo(0, 0);
        }
        logEvent(url);
      });

      logEvent(window.location?.pathname);
      return () => {
        routers.events.off("routeChangeComplete", logEvent);
      };
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") setMinHeight(`${window.innerHeight}px`);
  });

  // useEffect(() => {
  //   firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
  // }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <MarketingScript />
        <Head>
          <title>side bizz</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
          />
          <script src="https://r.moshimo.com/af/r/maftag.js"></script>
          <script>
            {`
              console.log("Calling mafcross method...");
              mafcross(["www.sidebizz.net", "members.sidebizz.net"]);
              console.log("mafcross method called.");
            `}
          </script>
        </Head>
        <TopProgressBar />
        <GlobalStyle />
        <ConfigProvider locale={i18n.language === "en-US" ? enUS : jaJP}>
          <AuthProvider>
            <ChatOptimizationProvider>
              <LayoutWrapper
                minHeight={minHeight}
                className={
                  routers?.pathname.startsWith("/chat") && "chat-layout"
                }
              >
                <Layout>
                  {noHeaderRoutes.includes(routers?.pathname) ? (
                    <Header isAuthenticated={false} />
                  ) : (
                    <Header isAuthenticated={true} />
                  )}
                  <Content>
                    <Component {...pageProps} />
                  </Content>
                  {routers?.pathname !== "/notifications" &&
                    routers?.pathname !== "/chat/[id]" &&
                    routers?.pathname !== "/chat" &&
                    routers?.pathname !== "/invite-sign-up" &&
                    routers?.pathname !== "/inquiry" &&
                    !noHeaderRoutes.includes(routers?.pathname) && <Footer />}
                </Layout>
              </LayoutWrapper>
            </ChatOptimizationProvider>
          </AuthProvider>
        </ConfigProvider>
      </QueryClientProvider>
    </>
  );
};

export default MyApp;
